export const pageMetaData = {
  accountManagement: {
    index: {
      title: "Account List | Account Management | Trustana",
    },
    edit: {
      title: "Edit Account | Account Management | Trustana",
    },
  },
  companyProfile: {
    view: {
      title: "View Company | Company Details | Trustana",
    },
    edit: {
      title: "Edit Company | Company Details | Trustana",
    },
  },
  product: {
    create: {
      title: "Create Product | Products | Trustana",
    },
    management: {
      title: "Product List | Products | Trustana",
    },
    edit: {
      title: "Edit Product | Products | Trustana",
    },
    details: {
      title: "Product Details | Products | Trustana",
    },
    import: {
      index: {
        title: "Import Upload | Products | Trustana",
      },
      header: {
        title: "Import Headers | Products | Trustana",
      },
      mapping: {
        title: "Import Mapping | Products | Trustana",
      },
      review: {
        title: "Import Review | Products | Trustana",
      },
      completed: {
        title: "Import Completed | Products | Trustana",
      },
    },
    uploadImage: {
      title: "Bulk Image Upload | Products | Trustana",
    },
    download: {
      index: {
        title: "File Download | Products | Trustana",
      },
    },
  },
  dam: {
    tools: {
      index: {
        title: "Tools | DAM | Trustana",
      },
      imageFormatConverter: {
        title: "Image Format Converter | DAM | Trustana",
      },
      imageResizer: {
        title: "Image Resizer | DAM | Trustana",
      },
      imageUpscaler: {
        title: "Image Upscaler | DAM | Trustana",
      },
      watermarkRemoval: {
        title: "Watermark Removal | DAM | Trustana",
      },
      aiGenerateBackground: {
        title: "Ai Generate Background | DAM | Trustana",
      },
      backgroundRemover: {
        title: "Background Remover | DAM | Trustana",
      },
      attributesGenerator: {
        title: "Attributes Generator | DAM | Trustana",
      },
    },
    image: {
      view: "Image Details | DAM |Trustana",
    },
    library: {
      index: {
        title: "Image Library | DAM | Trustana",
      },
    },
    task: {
      index: {
        title: "Task List | DAM | Trustana",
      },
    },
  },
  localization: {
    management: {
      title: "Locale Product List | Localization | Trustana",
    },
    create: {
      title: "Create Localization | Localization | Trustana",
    },
    edit: {
      title: "Edit Localized Product | Localization | Trustana",
    },
    details: {
      title: "Localized Product Details | Localization | Trustana",
    },
  },
  export: {
    management: {
      title: "Custom Template List | Export | Trustana",
    },
  },
  enrichment: {
    management: {
      title: "Enrich Product List | Enrichment | Trustana",
    },
    create: {
      title: "Create Enrichment | Enrichment | Trustana",
    },
  },
  quotationRequests: {
    index: {
      title: "Enquiry List | Enquiries | Trustana",
    },
    details: {
      title: "Enquiry Details | Enquiries | Trustana",
    },
  },
  attribute: {
    index: {
      title: "Attribute List | Attributes | Trustana",
    },
    addAttribute: {
      title: "Attribute Library | Attributes | Trustana",
    },
  },
  catalog: {
    index: {
      title: "E-Catalog List | Catalogs | Trustana",
    },
    edit: {
      title: "E-Catalog Edit | Catalogs | Trustana",
    },
  },
  dashboard: {
    index: {
      title: "Dashboard | Account | Trustana",
    },
    settings: {
      title: "Settings | Account | Trustana",
    },
    update: {
      email: {
        title: "Update E-mail | Account | Trustana",
      },
      password: {
        title: "Update Password | Account | Trustana",
      },
    },
  },
  login: {
    index: {
      title: "Login | Account | Trustana",
    },
    forgotPassword: {
      title: "Reset Password | Account Setup | Trustana",
    },
    setPassword: {
      title: "Set Password | Account Setup | Trustana",
    },
  },
  gettingStarted: {
    index: "Getting Started | Account Setup | Trustana",
  },
  buyerNetwork: {
    index: "Buyer List | Buyer Network  | Trustana",
    review: "Review | Buyer Network  | Trustana",
  },
  apiKey: {
    title: "API key | Account Management | Trustana",
  },
  distribution: {
    title: "Channel Product List | Distribution | Trustana",
  },
  channels: {
    index: "Overview | Channels | Trustana",
    configuration: "Channel Configuration | Channels | Trustana",
  },
};
